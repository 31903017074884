<template>
  <el-select
    v-cancel-read-only
    class="w-full"
    :model-value="modelValue"
    filterable
    :placeholder="
      $t('pages.purchase_order.entries.product_search_select.placeholder')
    "
    :disabled="!supplierId"
    @update:modelValue="onChange"
  >
    <el-option
      v-for="option in productsOptions"
      :key="option.value"
      :label="option.label"
      :value="option.value"
    />
  </el-select>
</template>

<script>
import th from '@tillhub/javascript-sdk'

export default {
  props: {
    modelValue: {
      type: String,
      default: null
    },
    products: {
      type: Array,
      required: true
    },
    supplierId: {
      type: String,
      default: null
    }
  },
  computed: {
    productsOptions() {
      return this.products
        .filter((item) => item.type !== 'variant')
        .map((item) => {
          const label = [item.custom_id, item.name]
            .filter((i) => i)
            .join(' - ')
            .trim()
          return {
            value: item.id,
            label
          }
        })
    }
  },
  methods: {
    onChange(productId) {
      this.$emit('update:modelValue', productId)
      this.$emit(
        'resource-set',
        this.products.find((item) => item.id === productId)
      )
    }
  }
}
</script>
