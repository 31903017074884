<template>
  <th-wrapper
    class="m-6"
    :title="$t('pages.purchase_order.form.placeholder.notes')"
  >
    <el-form ref="form">
      <el-row :gutter="10">
        <!-- Notes -->
        <el-col>
          <el-form-item prop="notes">
            <el-input id="notes" v-model="notes" data-testid="notes" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  computed: {
    notes: {
      get() {
        return this.modelValue.notes
      },
      set(value) {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          notes: value
        })
      }
    }
  },
  methods: {
    validate(cb) {
      cb(true)
    }
  }
}
</script>
