import baseModel from '@/models/base-model-new'

export default {
  setup(model) {
    const methods = {
      parseResponse(response) {
        const data = response?.data || {}
        return {
          ...data,
          products: (data.products || []).map((item) => ({
            ...item,
            price: Number(item.price),
            vatPercent: Number(item.vatPercent),
            discountPercent: Number(item.discountPercent),
            quantity: Number(item.quantity),
            totalUntaxed: Number(item.totalUntaxed),
            totalWithTax: Number(item.totalWithTax)
          }))
        }
      },
      parseDataBeforeSave(data) {
        const { products, ...everythingElse } = data
        return everythingElse
      }
    }
    return baseModel.setup(model, 'purchaseOrders', methods)
  }
}
