import Decimal from 'decimal.js-light'

export function getTotalWithTax(price, quantity, discountPercent) {
  return new Decimal(1)
    .minus(discountPercent)
    .mul(price)
    .mul(quantity)
    .toNumber()
}
export function getTotalUntaxed(totalWithTax, taxRate) {
  const taxAmount = new Decimal(totalWithTax).mul(taxRate).toNumber()
  return totalWithTax - taxAmount
}
