<template>
  <th-page-wrapper
    class="text-th-primary h-full"
    align-title="center"
    @delete="handleDelete"
    @save="handleSubmit"
  >
    <supplier-form ref="form" />

    <template #actions>
      <section class="actions">
        <el-button @click="handleCancel">
          {{ $t('common.interactions.buttons.back') }}
        </el-button>

        <el-dropdown
          v-permissions="{ scopes: ['supplier_management:purchase_orders'] }"
          type="primary"
          split-button
          @click="handleSubmit(false)"
        >
          <p data-testid="save-button">
            {{
              isNew
                ? $t('common.interactions.buttons.create')
                : $t('common.interactions.buttons.save')
            }}
          </p>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="handleSubmit(true)">
                <span>
                  {{
                    isNew
                      ? $t('pages.purchase_orders.edit.buttons.create_and_send')
                      : $t('pages.purchase_orders.edit.buttons.save_and_send')
                  }}
                </span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </section>
    </template>
  </th-page-wrapper>
</template>

<script>
import SupplierForm from './components/form'
import backButton from '@/mixins/back-button'

export default {
  metaInfo() {
    return {
      title: this.$t('Purchase orders')
    }
  },
  components: { SupplierForm },
  setup() {
    const { goBack } = backButton({ name: 'purchase-orders-list' })

    return { goBack }
  },
  computed: {
    isNew() {
      const id = this.$route.params.id
      return !id || id === 'new'
    }
  },
  methods: {
    handleSubmit(sendAfterSubmit) {
      this.$refs.form.handleSubmit(sendAfterSubmit)
    },
    handleDelete() {
      this.$refs.form.handleDelete()
    },
    handleCancel() {
      this.goBack()
    }
  }
}
</script>
<style scoped>
.actions {
  display: flex;
  height: 100%;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
  padding: 0 2rem;
  background: #fff;
}
</style>
