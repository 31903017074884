<template>
  <tr class="border-b align-middle">
    <!-- Product name -->
    <td
      ref="productTextCell"
      class="overflow-hidden"
      :style="{ 'max-width': productColWidth, 'text-overflow': 'ellipsis' }"
    >
      <el-tooltip effect="dark" placement="top" :content="productText">
        <span ref="productText">
          {{ productText }}
        </span>
      </el-tooltip>
    </td>

    <!-- Purchase price -->
    <td class="text-right">
      <th-currency-input v-model="price" :currency="currency" />
    </td>

    <!-- Quantity -->
    <td>
      <th-number-input
        v-model="quantity"
        positive-only
        submit-on-enter-key
        :locale="$i18n.locale"
        :data-testid="`${modelValue.productId}_quantity`"
      />
    </td>

    <!-- Discount -->
    <td>
      <th-number-input
        v-model="discountPercent"
        percent
        submit-on-enter-key
        :precision="2"
        :upper-limit="1.0"
        :locale="$i18n.locale"
        :placeholder="$formatNumber(0, { style: 'percent' })"
        :disabled="!price"
        positive-only
      />
    </td>

    <!-- Total price -->
    <td>
      <th-currency-input
        :model-value="modelValue.totalWithTax"
        :currency="currency"
        disabled
      />
    </td>

    <td class="text-left">
      <!-- Delete -->
      <div class="w-32">
        <el-button
          icon="Delete"
          class="el-button--text-icon"
          @click="$emit('delete')"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import { getDefaultCurrency } from '@/utils/general'
import { getTotalWithTax, getTotalUntaxed } from './price'

export default {
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    resources: {
      type: Object,
      default: () => ({})
    },
    products: {
      type: Array,
      required: true
    },
    productColWidth: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      currency: getDefaultCurrency()
    }
  },

  computed: {
    taxes() {
      return this.resources.taxes || []
    },
    price: {
      get() {
        return this.modelValue.price
      },
      set(value) {
        this.$emit('update:modelValue', this.getEntry('price', value || 0))
      }
    },

    quantity: {
      get() {
        return this.modelValue.quantity
      },
      set(value) {
        this.$emit('update:modelValue', this.getEntry('quantity', value || 0))
      }
    },

    discountPercent: {
      get() {
        return this.modelValue.discountPercent
      },
      set(value) {
        this.$emit(
          'update:modelValue',
          this.getEntry('discountPercent', value || 0)
        )
      }
    },

    taxRate() {
      const tax = this.taxes.find((tax) => tax.id === this.product?.tax) || {}
      return tax.rate || 0
    },

    product() {
      return (
        this.products.find((item) => item.id === this.modelValue.productId) ||
        null
      )
    },

    productText() {
      if (!this.product) {
        return '--'
      }
      const { custom_id, name } = this.product
      const productParts = [custom_id, name].filter(Boolean)
      if (!productParts.length) return '--'
      return productParts.join(' - ')
    }
  },

  methods: {
    getEntry(name, value) {
      const entry = {
        ...this.modelValue,
        price: this.price,
        quantity: this.quantity,
        discountPercent: this.discountPercent,
        totalWithTax: 0,
        totalUntaxed: 0
      }
      entry[name] = value

      const totalWithTax = getTotalWithTax(
        entry.price,
        entry.quantity,
        entry.discountPercent
      )
      const totalUntaxed = getTotalUntaxed(totalWithTax, this.taxRate)
      entry.totalWithTax = totalWithTax
      entry.totalUntaxed = totalUntaxed

      return entry
    }
  }
}
</script>
