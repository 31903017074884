<template>
  <th-page-wrapper v-loading="isLoading">
    <!-- General information  -->
    <purchase-order-general
      ref="purchase-order-information"
      v-model="current"
      :resources="resources"
      :is-new="isNew"
      :generate-purchase-order-number="false"
    />

    <!-- Entries -->
    <purchase-order-entries
      v-if="current"
      ref="purchase-order-entries"
      v-model="current"
      :resources="resources"
      data-testid="order-entries"
      @request-delete="addEntryForDeleteSequence"
      @request-update="addEntryForUpdateSequence"
    />

    <!-- Notes -->
    <purchase-order-notes ref="purchase-order-notes" v-model="current" />

    <purchase-order-send-modal @close="handleCloseSendModal" />
  </th-page-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'
import th from '@tillhub/javascript-sdk'
import { ref } from 'vue'
import { mapGetters } from 'vuex'
import { usePurchaseOrdersStore } from '@/store/purchaseOrders'
import get from 'just-safe-get'
import purchaseOrderModel from '../model/purchase-order-model'
import { genInitialData } from '../helpers'
import PurchaseOrderGeneral from './purchase-order-general'
import PurchaseOrderEntries from './purchase-order-entries'
import PurchaseOrderNotes from './purchase-order-notes'
import PurchaseOrderSendModal from './purchase-order-send-modal'

export default {
  components: {
    PurchaseOrderGeneral,
    PurchaseOrderEntries,
    PurchaseOrderNotes,
    PurchaseOrderSendModal
  },
  setup() {
    const isLoading = ref(false)
    const resources = ref({})
    const entryIdsToDelete = ref([])
    const entryIdsToUpdate = ref([])
    const purchaseOrdersStore = usePurchaseOrdersStore()

    const {
      id,
      isNew,
      current,
      sync,
      fetch,
      reset,
      save
    } = purchaseOrderModel.setup(genInitialData())

    return {
      id,
      isNew,
      current,
      sync,
      fetch,
      reset,
      save,
      isLoading,
      resources,
      entryIdsToDelete,
      entryIdsToUpdate,
      purchaseOrdersStore
    }
  },
  computed: {
    ...mapGetters({
      navigationAfterCreation: 'Config/getNavigationAfterCreation'
    }),
    generateSupplierNumber() {
      return safeGet(
        this.clientAccountConfiguration,
        'suppliers.generate_supplier_number',
        false
      )
    }
  },
  async mounted() {
    this.id = this.$route.params.id
    this.isLoading = true
    await Promise.allSettled([this.fetchResources(), this.fetchModel()])
    await this.fetchProposedData()
    this.sync()
    this.isLoading = false
  },
  methods: {
    async fetchModel() {
      await this.fetch()
    },
    async fetchResources() {
      this.resources = await this.$resourceFetch(
        'suppliers',
        'taxes',
        'branches'
      )
    },
    async fetchProposedData() {
      if (!this.id && this.purchaseOrdersStore.purchaseOrderProposal) {
        this.current.businessPartner = {
          id: this.purchaseOrdersStore.purchaseOrderProposal.supplierId
        }
        this.current.products = this.purchaseOrdersStore.purchaseOrderProposal.products.map(
          (product) => {
            const vatPercent =
              this.resources.taxes.find((tax) => tax.id === product?.tax)
                ?.rate || 0
            return {
              productId: product.id,
              productName: product.name,
              price:
                get(product, 'prices.purchase_prices.0.amount.gross') ||
                get(product, 'prices.default_prices.0.purchase_price') ||
                0,
              vatPercent,
              discountPercent: 0,
              quantity: 0,
              totalUntaxed: 0,
              totalWithTax: 0
            }
          }
        )
        this.purchaseOrdersStore.resetPurchaseOrderProposal()
      }
    },
    async handleSubmit(sendAfterSubmit) {
      const isNew = this.isNew
      this.$ampli.eventWithBaseProps(
        isNew
          ? 'purchaseOrderCreateButtonClick'
          : 'purchaseOrderSaveButtonClick'
      )
      //validate the form
      const isValid = await this.validate()

      if (!isValid) {
        return this.$message({
          type: 'warning',
          message: this.$t('common.forms.message.invalid_inputs')
        })
      }
      //save
      const resource = this.$t('common.resource.purchase_order.singular')
      const { error } = await this.saveModel()
      if (error) {
        //save fail
        const errorMessage = isNew
          ? this.$t('common.error.action.create.single', { resource })
          : this.$t('common.error.action.update.single', { resource })

        this.$logException(error, {
          message: errorMessage,
          trackError: false
        })
        return
      }

      //save success
      const successMessage = isNew
        ? this.$t('common.success.action.create.single', { resource })
        : this.$t('common.success.action.update.single', { resource })

      this.$message({
        type: 'success',
        message: successMessage
      })

      if (sendAfterSubmit) {
        if (!isNew) {
          await this.fetchModel()
        }
        this.$emitter.emit('email-preview', {
          id: this.id,
          businessPartner: this.current.businessPartner
        })
      } else {
        if (isNew) {
          if (this.navigationAfterCreation === 'edit' && this.id) {
            this.routeTo(this.id)
          } else {
            this.routeTo()
          }
        } else {
          await this.fetchModel()
          this.routeTo()
        }
      }
    },
    async saveModel() {
      const { products } = this.current
      const { error, data } = await this.save()
      this.current.products = products
      if (error) {
        return { error }
      }
      try {
        await this.saveProducts(data.id, products)
      } catch (err) {
        return { error: err }
      }
      return {}
    },
    async saveProducts(id, products) {
      const promises = []
      const entriesToAdd = products.filter(
        (item) =>
          (!item.id || this.entryIdsToUpdate.includes(item.id)) &&
          item.quantity > 0
      )
      if (entriesToAdd.length) {
        promises.push(
          th.purchaseOrders().bulkAddProducts(id, {
            products: entriesToAdd
          })
        )
      }
      if (this.entryIdsToDelete.length) {
        promises.push(
          th.purchaseOrders().bulkDeleteProducts(id, {
            products: this.entryIdsToDelete
          })
        )
      }
      await Promise.all(promises)
      this.entryIdsToDelete = []
    },
    addEntryForDeleteSequence(id) {
      this.entryIdsToDelete.push(id)
    },
    addEntryForUpdateSequence(id) {
      this.entryIdsToUpdate.push(id)
    },
    async handleDelete() {
      const confirm = await this.$askToDelete(this.current.companyName)
      if (confirm) this.delete()
    },
    async delete() {
      const { error } = await this.deleteModel()
      if (error) {
        //Delete failed
        const errorMessage = this.$t('common.error.action.delete.single', {
          resource: this.$t('common.resource.purchase_order.singular')
        })

        this.$logException(error, { message: errorMessage })
        return
      }

      //Delete success
      this.routeTo()
    },
    handleCloseSendModal() {
      if (this.$route.params.id) return
      this.routeTo(
        this.navigationAfterCreation === 'edit' && this.id ? this.id : ''
      )
    },
    routeTo(path) {
      this.reset() //this will make model clean (not dirty)
      const routePath = `/supplier-management/purchase-orders${
        path ? '/' + path : ''
      }`
      this.$router.push(routePath)
    },
    async validate() {
      // Validate all sections
      let valid = true
      for (const key in this.$refs) {
        if (key.includes('purchase-order') && this.$refs[key].validate) {
          if (!(await this.validateAttribute(key))) valid = false
        }
      }
      return valid
    },
    validateAttribute(key) {
      return new Promise((resolve) => {
        this.$refs[key].validate(resolve)
      })
    }
  }
}
</script>
