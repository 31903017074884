<template>
  <th-wrapper class="m-6" :title="$t('common.titles.general_info.title')">
    <el-form ref="form" :model="form" :rules="rules">
      <el-row :gutter="10">
        <!-- Purchase order number -->
        <el-col :span="8">
          <el-form-item
            prop="purchaseOrderNumber"
            :label="
              $t('pages.purchase_order.form.placeholder.purchase_order_number')
            "
          >
            <el-input
              id="purchaseOrderNumber"
              v-model="purchaseOrderNumber"
              :disabled="generatePurchaseOrderNumber"
              :placeholder="
                generatePurchaseOrderNumber
                  ? $t('pages.staff.form.hint.staff_id.is_auto')
                  : $t(
                      'pages.purchase_order.form.placeholder.purchase_order_number'
                    )
              "
            />
          </el-form-item>
        </el-col>

        <!-- Supplier -->
        <el-col :span="8">
          <el-form-item
            prop="supplier"
            :label="$t('common.resource.supplier.singular')"
          >
            <supplier-select
              id="supplier"
              v-model="supplier"
              :disabled="!isNew"
              :resources="resources"
              data-testid="supplier-select"
            />
          </el-form-item>
        </el-col>

        <!-- Branch -->
        <el-col :span="8">
          <el-form-item
            prop="branch"
            :label="$t('common.resource.branch.singular')"
          >
            <branch-select
              id="location"
              v-model="location"
              :resources="resources"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import BranchSelect from './general/branch-select.vue'
import SupplierSelect from './general/supplier-select.vue'

export default {
  components: {
    BranchSelect,
    SupplierSelect
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    generatePurchaseOrderNumber: {
      type: Boolean,
      required: true
    },
    isNew: {
      type: Boolean,
      default: true
    },
    resources: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    purchaseOrderNumber: {
      get() {
        return this.modelValue.purchaseOrderNumber
      },
      set(value) {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          purchaseOrderNumber: value
        })
      }
    },
    supplier: {
      get() {
        return this.modelValue.businessPartner?.id || null
      },
      set(value) {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          businessPartner: {
            id: value
          }
        })
      }
    },
    location: {
      get() {
        return this.modelValue.location || null
      },
      set(value) {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          location: value
        })
      }
    },
    form() {
      return {
        purchaseOrderNumber: this.purchaseOrderNumber,
        supplier: this.supplier
      }
    },
    rules() {
      return {
        purchaseOrderNumber: [
          {
            required: !this.generatePurchaseOrderNumber,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: ['blur', 'change']
          }
        ],
        supplier: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  methods: {
    validate(cb) {
      this.$refs.form.validate(cb)
    }
  }
}
</script>
