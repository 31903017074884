<template>
  <div>
    <el-select
      v-model="value"
      v-cancel-read-only
      class="w-full"
      filterable
      clearable
      :disabled="disabled"
    >
      <el-option-group
        v-if="showSupplierCreateOption"
        v-permissions="{ scopes: ['supplier_management:suppliers:create'] }"
      >
        <el-option
          :label="$t('pages.purchase_order.form.create_supplier')"
          :value="''"
          @click.stop="
            $router.push({
              name: 'supplier-new',
              query: { redirectedFrom: $route.fullPath }
            })
          "
        />
      </el-option-group>
      <el-option-group v-if="suppliers.length">
        <el-option
          v-for="item in suppliers"
          :key="item.id"
          :label="item.label"
          :value="item.id"
        />
      </el-option-group>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: null
    },
    resources: {
      type: Object,
      default: () => ({})
    },
    showSupplierCreateOption: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    suppliers() {
      return (this.resources.suppliers || []).map(({ id, companyName }) => ({
        id,
        label: companyName
      }))
    }
  }
}
</script>
