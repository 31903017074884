<template>
  <el-form
    ref="form"
    class="th-table-responsive bg-th-primary-light"
    :model="form"
    :rules="rules"
    data-testid="entry-add-row"
    @submit.prevent="submitForm"
  >
    <table class="th-table">
      <thead>
        <tr>
          <th class="py-0">
            <div :style="{ 'min-width': productColWidth }" />
          </th>
          <th class="min-w-field py-0 w-40" />
          <th class="min-w-field py-0 w-40" />
          <th class="min-w-field py-0 w-40" />
          <th class="min-w-field py-0 w-40" />
          <th class="min-w-field py-0 w-40" />
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>
            <el-form-item
              :label="$t('pages.purchase_order.entries.all.table.product')"
              prop="productId"
            >
              <product-search-select
                :model-value="productId"
                class="w-full"
                :products="productsWithoutChosen"
                :supplier-id="supplierId"
                data-testid="product-select"
                @resource-set="selectProduct"
              />
            </el-form-item>
          </td>

          <td>
            <el-form-item
              :label="
                $t('pages.purchase_order.entries.all.table.purchase_price')
              "
            >
              <th-currency-input
                v-model="form.price"
                :currency="currency"
                :disabled="!form.product"
                @cleared="form.price = 0"
              />
            </el-form-item>
          </td>

          <td>
            <el-form-item
              :label="$t('pages.purchase_order.entries.all.table.quantity')"
            >
              <th-number-input
                v-model="form.quantity"
                positive-only
                submit-on-enter-key
                :disabled="!form.product"
                :locale="$i18n.locale"
                @cleared="form.quantity = 0"
              />
            </el-form-item>
          </td>

          <td>
            <el-form-item
              :label="
                $t('pages.purchase_order.entries.all.table.discounted_by')
              "
            >
              <th-number-input
                v-model="form.discountPercent"
                percent
                submit-on-enter-key
                :precision="2"
                :upper-limit="1.0"
                :locale="$i18n.locale"
                :placeholder="$formatNumber(0, { style: 'percent' })"
                :disabled="!form.price"
                positive-only
                @cleared="form.discountPercent = 0"
              />
            </el-form-item>
          </td>

          <td>
            <el-form-item
              :label="$t('pages.purchase_order.entries.all.table.total_price')"
            >
              <th-currency-input
                :model-value="totalWithTax"
                :precision="2"
                :currency="currency"
                disabled
              />
            </el-form-item>
          </td>
          <td>
            <el-button
              class="w-32"
              data-testid="add-button"
              type="primary"
              plain
              native-type="submit"
              :disabled="!form.product"
              v-text="$t('common.interactions.buttons.add')"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </el-form>
</template>

<script>
import get from 'just-safe-get'
import ProductSearchSelect from './product-search-select'
import { getDefaultCurrency } from '@/utils/general'
import { getTotalWithTax, getTotalUntaxed } from './price'

const getInitialProductEntry = () => {
  return {
    product: null,
    price: 0,
    quantity: 0,
    discountPercent: 0
  }
}

export default {
  components: {
    ProductSearchSelect
  },
  props: {
    entriesProductIds: {
      type: Array,
      default: () => []
    },
    products: {
      type: Array,
      required: true
    },
    resources: {
      type: Object,
      default: () => ({})
    },
    productColWidth: {
      type: String,
      default: ''
    },
    supplierId: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      form: getInitialProductEntry(),
      currency: getDefaultCurrency(),
      rules: {
        product: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(
                  new Error(
                    this.$t('common.forms.rules.field_warnings.required')
                  )
                )
              } else {
                callback()
              }
            }
          }
        ]
      }
    }
  },
  computed: {
    taxes() {
      return this.resources.taxes || []
    },
    productId() {
      return this.form.product?.id || null
    },
    productsWithoutChosen() {
      const purchaseOrderParentProductIds = this.products
        .filter((product) => this.entriesProductIds.includes(product.id))
        .map((product) => product.parent)
        .filter((parentProductId) => parentProductId)
      return this.products.filter(
        (product) =>
          !this.entriesProductIds.includes(product.id) &&
          !purchaseOrderParentProductIds.includes(product.id) &&
          product.stockable
      )
    },
    totalWithTax() {
      return getTotalWithTax(
        this.form.price,
        this.form.quantity,
        this.form.discountPercent
      )
    },
    totalUntaxed() {
      return getTotalUntaxed(this.totalWithTax, this.taxRate)
    },
    taxRate() {
      return this.getProductTax(this.form.product)
    }
  },
  methods: {
    async submitForm() {
      const valid = await this.validate()
      if (!valid) {
        return this.$message({
          type: 'warning',
          message: this.$t('common.forms.warning.invalid_input.message')
        })
      }

      const isParentProduct = this.form.product.type === 'variant_product'

      const entry = {
        productId: this.form.product.id,
        productName: this.form.product.name,
        price: this.form.price,
        vatPercent: this.taxRate,
        discountPercent: this.form.discountPercent,
        quantity: this.form.quantity,
        totalUntaxed: this.totalUntaxed,
        totalWithTax: this.totalWithTax
      }

      // We're automatically adding product variants if a product with variants selected
      const variantEntries = this.products
        .filter((item) => item.parent === entry.productId)
        .map((item) => {
          return {
            productId: item.id,
            productName: item.name,
            price: this.form.price,
            vatPercent: this.getProductTax(item),
            discountPercent: this.form.discountPercent,
            quantity: this.form.quantity,
            totalUntaxed: this.totalUntaxed,
            totalWithTax: this.totalWithTax
          }
        })

      if (isParentProduct) {
        this.$emit('entries', [...variantEntries])
      } else {
        this.$emit('entries', [entry])
      }
      this.reset()
    },

    async validate() {
      return new Promise((resolve, _) => {
        this.$refs.form.validate(resolve)
      })
    },

    reset() {
      this.$refs.form.resetFields()
      this.form = getInitialProductEntry()
    },

    selectProduct(product) {
      if (product) {
        this.form.product = product
        this.form.price = this.getProductPurchasePrice(product)
      } else {
        this.reset()
      }
    },

    getProductPurchasePrice(product) {
      return (
        get(product, 'prices.purchase_prices.0.amount.gross') ||
        get(product, 'prices.default_prices.0.purchase_price') ||
        0
      )
    },

    getProductTax(product) {
      const tax = this.taxes.find((tax) => tax.id === product?.tax) || {}
      return tax.rate || 0
    }
  }
}
</script>

<style scoped>
.th-table td {
  padding-top: 0;
}
</style>
