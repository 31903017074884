<template>
  <div>
    <el-select
      v-model="value"
      v-cancel-read-only
      class="w-full"
      filterable
      clearable
      :disabled="disabled"
    >
      <el-option-group v-if="branches.length">
        <el-option
          v-for="item in branches"
          :key="item.id"
          :label="item.label"
          :value="item.id"
        />
      </el-option-group>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: null
    },
    resources: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    branches() {
      return (this.resources.branches || []).map(({ id, name }) => ({
        id,
        label: name
      }))
    }
  }
}
</script>
