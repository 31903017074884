<template>
  <th-wrapper
    :title="$t('pages.purchase_order.entries.title')"
    :info="$t('pages.purchase_order.entries.info')"
    body-class="p-0"
    class="m-6 border"
    header-class="bg-th-primary-light pb-4"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <!-- Additional header -->
      <entry-add-row
        :entries-product-ids="entriesProductIds"
        :products="products"
        :resources="resources"
        :product-col-width="productColWidth"
        :supplier-id="supplierId"
        @entries="addEntries"
      />

      <!-- Table -->
      <div class="th-table-responsive shadow-th-light">
        <table class="th-table" data-testid="table-entries">
          <thead>
            <tr>
              <th class="min-w-field py-2 h-12">
                <div
                  class="flex h-full w-full items-center"
                  :style="{ 'min-width': productColWidth }"
                >
                  <!-- Product name -->
                  <span>
                    {{ $t('pages.purchase_order.entries.all.table.product') }}
                  </span>
                </div>
              </th>
              <th class="min-w-field py-2 h-12 w-40">
                <!-- Purchase Price -->
                {{
                  $t('pages.purchase_order.entries.all.table.purchase_price')
                }}
              </th>
              <th class="min-w-field py-2 h-12 w-40">
                <!-- Quantity -->
                {{ $t('pages.purchase_order.entries.all.table.quantity') }}
              </th>
              <th class="min-w-field py-2 h-12 w-40">
                <!-- Discount -->
                {{ $t('pages.purchase_order.entries.all.table.discounted_by') }}
              </th>
              <th class="min-w-field py-2 h-12 w-40">
                <!-- Total price -->
                {{ $t('pages.purchase_order.entries.all.table.total_price') }}
              </th>
              <th class="py-2 h-12 w-0" />
            </tr>
          </thead>

          <tbody>
            <entry-row
              v-for="(entry, index) in childrenPage"
              :key="entry.id"
              :model-value="entry"
              :resources="resources"
              :product-col-width="productColWidth"
              :products="products"
              @update:modelValue="updateChildrenPageEntry($event, index)"
              @delete="deleteChildrenPageEntry(entry)"
            />
          </tbody>
        </table>

        <!-- Table no data -->
        <div
          v-if="!entries || entries.length === 0"
          class="py-5 px-8 text-gray-500"
        >
          {{ $t('common.data.no_data_yet') }}
          <el-form-item prop="products" />
        </div>

        <!-- Pagination -->
        <th-pagination
          v-if="entries && entries.length > 0"
          class="bg-th-primary-light"
          :page-size="pageSizes.inline"
          :total="entries.length"
          :current-page="page"
          @current-change="(v) => (page = v)"
          @size-change="changePageSize($event)"
        />
      </div>
    </el-form>
  </th-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import th from '@tillhub/javascript-sdk'
import EntryRow from './entries/entry-row.vue'
import EntryAddRow from './entries/entry-add-row.vue'

export default {
  components: {
    EntryRow,
    EntryAddRow
  },

  props: {
    modelValue: {
      type: Object,
      required: true
    },
    resources: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      page: 1,
      headers: [],
      productColWidth: '15rem',
      products: [],
      supplierProductIds: []
    }
  },

  computed: {
    ...mapGetters({
      pageSizes: 'Config/getPageSizes',
      defaultCurrency: 'Config/getCurrentDefaultCurrency'
    }),

    form() {
      return {
        products: this.modelValue.products
      }
    },
    rules() {
      return {
        products: [
          {
            validator: (rule, value, callback) => {
              if (!value || !value.length) {
                callback(
                  new Error(
                    this.$t('common.forms.rules.field_warnings.required')
                  )
                )
              } else {
                callback()
              }
            }
          }
        ]
      }
    },

    supplierId() {
      return this.modelValue.businessPartner?.id || null
    },

    entries() {
      return this.modelValue.products || []
    },

    childrenPage() {
      return this.entries.slice(
        (this.page - 1) * this.pageSizes.inline,
        this.page * this.pageSizes.inline
      )
    },

    entriesProductIds() {
      return this.entries.map((e) => e.productId)
    }
  },
  watch: {
    childrenPage() {
      // Update the page in case of deleting all the entries of a page
      if (!this.childrenPage.length && this.page !== 1) {
        this.page = this.page - 1
      }
    },
    supplierId: {
      immediate: true,
      async handler() {
        await this.initSupplierProducts()
      }
    }
  },

  methods: {
    async fetchSingleProductOption(id) {
      try {
        const { data } = await th.products().get(id)
        if (!data) {
          this.$logException(new Error(`product if ID ${id} not found`))
          return
        }
        this.products.push(data)
      } catch (error) {
        this.$logException(error)
      }
      return null
    },

    async fetchRelatedProductIds() {
      if (!this.supplierId) {
        return
      }
      try {
        const {
          data = { productId: [] }
        } = await th.suppliersProductsRelation().getProductIds(this.supplierId)

        this.supplierProductIds = data.productId
      } catch (error) {
        this.$logException(error)
      }
    },

    async initSupplierProducts() {
      this.products = []
      this.supplierProductIds = []

      await this.fetchRelatedProductIds()

      const promises = this.supplierProductIds.map((id) => {
        return this.fetchSingleProductOption(id)
      })
      await Promise.all(promises)
    },
    updateChildrenPageEntry(entry, index) {
      Object.assign(this.childrenPage[index], entry)
      // That means the product was already saved to API, but we need to update it now
      if (entry.id) {
        this.$emit('request-update', entry.id)
      }
    },

    addEntries(entries) {
      this.$emit('update:modelValue', {
        ...this.modelValue,
        products: [...this.modelValue.products, ...entries]
      })
    },

    deleteChildrenPageEntry(entry) {
      const products = this.entries.filter((item) => item !== entry)
      this.$emit('update:modelValue', {
        ...this.modelValue,
        products
      })

      // That means the product was already saved to API
      if (entry.productId) {
        this.$emit('request-delete', entry.productId)
      }
    },
    changePageSize(value) {
      this.$store.dispatch('Config/setLocalConfigurationValue', {
        path: 'pageSizes.inline',
        value
      })
    },

    validate(cb) {
      this.$refs.form.validate(cb)
    }
  }
}
</script>

<style scoped>
.search-in-table :deep(.el-input__prefix) {
  left: 3px;
}

.search-in-table :deep(.el-input__inner) {
  padding-left: 25px !important;
  font-size: 12px;
  line-height: 1.2;
}

.search-in-table :deep(.el-input__prefix svg) {
  height: 18px !important;
  width: 18px !important;
  fill: currentColor;
}
</style>
